import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import slugify from "@sindresorhus/slugify";
import "@algolia/autocomplete-theme-classic";

const searchClient = algoliasearch(
  "4E2MBXEFUP",
  "1c5712985703143a8c1abe7cd8caafdd"
);

autocomplete({
  container: "#autocomplete",
  placeholder: "Search for a game",
  getSources({ query }) {
    return [
      {
        sourceId: "products",
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: "flipgrip_games",
                query,
                params: {
                  hitsPerPage: 5,
                  // attributesToSnippet: ["name:10", "description:35"],
                  // snippetEllipsisText: "…",
                },
              },
            ],
          });
        },
        templates: {
          item({ item, components, html }) {
            return html`
              <a href="/games/${slugify(item.name)}" class="aa-ItemLink">
                <div class="aa-ItemContent">
                  <div>
                    <img
                      src="https://res.cloudinary.com/doilvmgxq/image/fetch/q_60,w_200,h_200,c_fill,g_auto:subject/${encodeURIComponent(
                        item.store_image
                      )}"
                      alt="${item.name}"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div class="aa-ItemContentBody">
                    <div class="aa-ItemContentTitle">
                      ${components.Highlight({
                        hit: item,
                        attribute: "name",
                      })}
                    </div>
                    <div class="aa-ItemContentDescription">
                    <p class="text-xs">${item.collections? item.collections.join(", "): ""}</p>
                    <p class="text-xs">${item.developers? item.developers.join(", "): ""}</p>
                    <p class="text-xs">${item.publishers? item.publishers.join(", "): ""}</p>
                    <p class="text-xs">${item.categories? item.categories.join(", "): ""}</p>
                    </div>
                  </div>
                </div>
              </a>`;
          },
        },
      },
    ];
  },
});
